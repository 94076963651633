import React, { Component } from 'react';
import logo from './wanderloop.png';

class ComingSoon extends Component {
    render() {
        return (
            <div className="Coming-soon">
                <h2>
                    The Way You Travel Is About <br />
                    To Change <span className="scale-up-center">FOREVER.</span>
                </h2>
            </div>
        );
    }
}

export default ComingSoon;